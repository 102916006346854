@import '../variables.css';

@define-mixin ml-font-family {
  font-family: var(--body-font-family);
}

@define-mixin ml-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
