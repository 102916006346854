:root {
  --color-purple: rgb(98, 86, 177);
  --color-purple-hover: rgb(92, 78, 167);
  --color-purple-pressed: rgb(76, 57, 142);
  --color-purple-alpha65: rgba(98, 86, 177, 0.65);
  --color-purple-alpha50: rgba(98, 86, 177, 0.5);
  --color-purple-alpha25: rgba(98, 86, 177, 0.25);
  --color-purple-alpha20: rgba(98, 86, 177, 0.2);
  --color-purple-alpha10: rgba(98, 86, 177, 0.1);
  --color-purple-10: rgb(241, 240, 248);

  --color-teal: rgb(0, 167, 144);
  --color-teal-hover: rgb(0, 134, 115);
  --color-teal-pressed: rgb(0, 100, 86);
  --color-teal-alpha65: rgba(0, 167, 144, 0.65);
  --color-teal-alpha50: rgba(0, 167, 144, 0.5);
  --color-teal-alpha25: rgba(0, 167, 144, 0.25);
  --color-teal-alpha20: rgba(0, 167, 144, 0.2);
  --color-teal-alpha10: rgba(0, 167, 144, 0.1);

  --color-dark-green: rgb(4, 87, 98);

  --color-yellow: rgb(247, 174, 16);
  --color-yellow-hover: rgb(216, 156, 16);
  --color-yellow-pressed: rgb(182, 117, 12);
  --color-yellow-alpha65: rgba(98, 86, 177, 0.65);
  --color-yellow-alpha50: rgba(98, 86, 177, 0.5);
  --color-yellow-alpha25: rgba(98, 86, 177, 0.25);
  --color-yellow-alpha20: rgba(98, 86, 177, 0.2);
  --color-yellow-alpha10: rgba(98, 86, 177, 0.1);

  --color-orange: rgb(255, 90, 0);
  --color-orange-darker: rgb(217, 75, 0);
  --color-orange-dark: rgb(179, 60, 0);
  --color-orange-alpha65: rgba(247, 174, 16, 0.65);
  --color-orange-alpha50: rgba(247, 174, 16, 0.5);
  --color-orange-alpha25: rgba(247, 174, 16, 0.25);
  --color-orange-alpha20: rgba(247, 174, 16, 0.2);
  --color-orange-alpha10: rgb(255, 238, 229);

  --color-smart: rgb(66, 39, 121);

  --color-neutral: rgb(27, 89, 172);
  --color-accepted: rgb(15, 185, 27);
  --color-critical: rgb(226, 20, 0);
  --color-warning: rgb(255, 196, 0);

  --color-white: rgb(255, 255, 255);
  --color-white-alpha20: rgba(255, 255, 255, 0.2);
  --color-white-alpha50: rgba(255, 255, 255, 0.5);

  --color-black: rgb(0, 0, 0);
  --color-black-alpha60: rgba(0, 0, 0, 0.6);
  --color-dark-gray: rgb(118, 118, 118);
  --color-light-gray: rgb(195, 195, 195);

  --color-text: rgb(34, 34, 34);
  --color-text-secondary: var(--color-dark-gray);
  --color-text-disabled: var(--color-light-gray);

  --color-text-link: var(--color-teal);
  --color-text-link-hover: var(--color-teal-hover);
  --color-text-link-pressed: var(--color-teal-pressed);

  --color-border: rgb(170, 170, 170);
  --color-divider: rgb(231, 231, 231);

  --color-background-gray-0: rgb(255, 255, 255);
  --color-background-gray-1: rgb(250, 250, 250);
  --color-background-gray-2: rgb(247, 247, 247);
  --color-background-gray-3: rgb(221, 221, 221);
  --color-background-gray-4: rgb(208, 208, 208);
  --color-background-aquamarine: rgb(242, 251, 249);
  --color-background-app: rgb(244, 248, 252);

  --body-font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --body-line-height: 1.15;

  --shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  --negative-shadow: 0px -2px 8px rgba(0, 0, 0, 0.16);

  --sm-viewport-max-width: 767px;
  --md-viewport-min-width: calc(var(--sm-viewport-max-width) + 1px);
  --md-viewport-max-width: 1063px;
  --lg-viewport-min-width: calc(var(--md-viewport-max-width) + 1px);
  --lg-viewport-max-width: 1199px;
  --xl-viewport-min-width: calc(var(--lg-viewport-max-width) + 1px);

  --z-index-modal: 1000;
  --z-index-toast: 1100;

  --spacing-xs: 2px;
  --spacing-sm: 4px;
  --spacing-md: 8px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  --spacing-xxl: 40px;

  --container-xs: 328px;
  --container-sm: 458px;
  --container-md: 550px;
  --container-lg: 878px;
  --container-xl: 1360px;

  --outline: 2px dotted var(--color-teal-hover);
  --outline-offset-small: 2px;
  --outline-offset-medium: 4px;
  --outline-offset-large: 8px;
}

@custom-media --hover-supported (hover: hover) and (pointer: fine);

/* Phones */
@custom-media --sm-viewport (max-width: 767px);
@custom-media --max-sm-viewport (max-width: 767px);

/* Portrait tablets */
@custom-media --md-viewport (min-width: 768px) and (max-width: 1063px);
@custom-media --min-md-viewport (min-width: 768px);
@custom-media --max-md-viewport (max-width: 1063px);

/* Landscape tablets, small desktops */
@custom-media --lg-viewport (min-width: 1064px) and (max-width: 1199px);
@custom-media --min-lg-viewport (min-width: 1064px);
@custom-media --max-lg-viewport (max-width: 1199px);

/* Large desktops */
@custom-media --xl-viewport (min-width: 1200px);
@custom-media --min-xl-viewport (min-width: 1200px);
