@import '../variables.css';

@define-mixin text-extra-large {
  font-size: 22px;
  line-height: 28px;
}

@define-mixin text-large {
  font-size: 16px;
  line-height: 22px;
}

@define-mixin text-medium {
  font-size: 14px;
  line-height: 19px;
}

@define-mixin text-small {
  font-size: 12px;
  line-height: 16px;
}

@define-mixin text-tiny {
  font-size: 10px;
  line-height: 16px;
}

@define-mixin text-normal {
  font-weight: 400;
}

@define-mixin text-semi-bold {
  font-weight: 600;
}

@define-mixin text-bold {
  font-weight: 700;
}

@define-mixin text-color-primary {
  color: var(--color-text);
}

@define-mixin text-color-secondary {
  color: var(--color-text-secondary);
}

@define-mixin text-color-neutral {
  color: var(--color-neutral);
}

@define-mixin text-color-accepted {
  color: var(--color-accepted);
}

@define-mixin text-color-critical {
  color: var(--color-critical);
}

@define-mixin text-color-warning {
  color: var(--color-warning);
}

@define-mixin text-color-purple {
  color: var(--color-purple);
}

@define-mixin text-color-orange {
  color: var(--color-orange);
}

@define-mixin text-color-teal {
  color: var(--color-teal);
}

@define-mixin text-color-disabled {
  color: var(--color-light-gray);
}

@define-mixin text-color-white {
  color: var(--color-white);
}

@define-mixin text-strike-through {
  text-decoration: line-through;
}

@define-mixin text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@define-mixin text-hyphens {
  hyphens: auto;
  overflow-wrap: break-word;
}

@define-mixin text-truncate $line: 2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}
