@import '../../common/variables.css';
@import '../../common/mixins/mediaQuery.css';
@import '../../common/mixins/font.css';
@import '../../common/mixins/text.css';

.button {
  @mixin ml-font-family;
  @mixin ml-font-smoothing;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border: 1px solid transparent;
  border-radius: 1000px;

  &[aria-disabled='true'] {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.buttonLoading {
  pointer-events: none;
  cursor: none;
}

.buttonPrimary {
  padding: 8px 16px;

  color: var(--color-white);
  background-color: var(--color-purple);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-purple-hover);
  }
  &:active {
    color: var(--color-white);
    background-color: var(--color-purple-pressed);
  }

  &[aria-disabled='true'] {
    background-color: var(--color-light-gray);
    color: var(--color-white);
  }

  &:focus-visible {
    background-color: var(--color-purple-hover);
    outline: var(--outline);
    outline-offset: var(--outline-offset-medium);
  }

  &[aria-disabled='true']:focus-visible {
    background-color: var(--color-light-gray);
    color: var(--color-white);
    outline: var(--outline);
    outline-offset: var(--outline-offset-medium);
  }
}

.buttonPrimary svg {
  color: currentcolor;
}

.buttonPrimaryLoading:hover {
  @mixin hover-supported {
    background-color: var(--color-purple-alpha65);
  }
}

.buttonSecondary {
  padding: 8px 16px;
  border-color: var(--color-purple);
  color: var(--color-purple);
  background-color: var(--color-white);

  &:hover {
    color: var(--color-purple-hover);
    border-color: var(--color-purple-hover);
    background-color: var(--color-white);
  }

  &:active {
    color: var(--color-purple-pressed);
    border-color: var(--color-purple-pressed);
    background-color: var(--color-white);
  }

  &[aria-disabled='true'] {
    background-color: var(--color-light-gray);
    border-color: var(--color-light-gray);
    color: var(--color-white);
  }

  &:focus-visible {
    outline: var(--outline);
    outline-offset: var(--outline-offset-medium);
  }
}

.buttonSecondary svg {
  color: currentcolor;
}

.buttonTertiary {
  background: none;
  border-radius: 0;
  padding: 8px 0;
  color: var(--color-teal);

  &:hover {
    color: var(--color-teal-hover);
  }

  &:active {
    color: var(--color-teal-pressed);
  }

  &[aria-disabled='true'] {
    color: var(--color-light-gray);
  }

  &:focus-visible {
    outline: var(--outline);
    outline-offset: var(--outline-offset-small);
  }
}

.buttonTertiary svg {
  color: currentcolor;
}

.buttonPlain {
  background: none;
  border-radius: 0;

  &:focus-visible {
    outline: var(--outline);
    outline-offset: var(--outline-offset-medium);
  }
}

.buttonFullWidth {
  display: flex;
  width: 100%;
}

.buttonHasOnlyIcon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 0;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize:first-letter {
  text-transform: uppercase;
}

.normal {
  @mixin text-normal;
}

.semiBold {
  @mixin text-semi-bold;
}

.bold {
  @mixin text-bold;
}

.buttonIcon {
  width: 24px;
  height: 24px;
}

.buttonIconWithSpacing {
  margin-right: var(--spacing-md);
}
